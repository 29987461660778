@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../fonts/Roboto/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../fonts/Roboto/Roboto-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../fonts/Roboto/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url('../fonts/Roboto/Roboto-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url('../fonts/Roboto/Roboto-LightItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url('../fonts/Roboto/Roboto-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url('../fonts/Roboto/Roboto-MediumItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../fonts/Roboto/Roboto-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

:root {
    --font-primary: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

    --color-dark-text: #1a202c;
    --color-dark-blue: #363f60;
    --color-strong-blue: #4280cc;
    --color-light-blue: #edf5ff;
    --color-dark-gray: #303439;
    --color-gray: #54595f;
    --color-light-gray: #a9a9b0;
    --color-light: #f7fafc;
    --color-white: #ffffff;
    --bg-color-header: var(--color-white);
    --bg-color-footer: var(--color-dark-gray);

    --spacing-quarter: 2px;
    --spacing-half: 4px;
    --spacing-three-quarters: 6px;
    --spacing-1: 8px;
    --spacing-2: 16px;
    --spacing-3: 24px;
    --spacing-4: 32px;
    --spacing-5: 40px;
    --spacing-6: 48px;
    --spacing-7: 56px;
    --spacing-8: 64px;
    --spacing-10: 80px;
    --spacing-12: 96px;
    --spacing-15: 120px;
    --spacing-20: 160px;
    --spacing-nav-height: 80px;
    --spacing-horizontal-desktop: var(--spacing-3);
    --spacing-horizontal-mobile: var(--spacing-2);
    --spacing-margin-small: 12px;

    --border-radius: 9px;

    --font-size-desktop: 16px;
    --font-size-desktop-small: 14px;
    --nav-size-desktop: 16px;
    --subnav-size-desktop: 15px;
    --header-h1-size-desktop: 40px;
    --header-h2-size-desktop: 30px;
    --header-h3-size-desktop: 24px;
    --header-h4-size-desktop: 20px;
    --header-h5-size-desktop: 18px;
    --header-h6-size-desktop: 16px;
    --header-h1-size-mobile: 32px;
    --header-h2-size-mobile: 26px;
    --header-h3-size-mobile: 20px;
    --header-h4-size-mobile: 18px;
    --header-h5-size-mobile: 16px;
    --font-size-btn: 20px;
    --font-size-btn-small: 16px;

    --line-height-body: 1.5;
    --line-height-header: 1.4;
}

body {
    background-color: var(--color-white);
    font-family: var(--font-primary);
    font-weight: 400;
    color: var(--color-dark-text);
    line-height: var(--line-height-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    letter-spacing: -0.025em;
    line-height: var(--line-height-header);
    margin: 0 0 var(--spacing-margin-small) 0;
}

h1 {
    font-size: var(--header-h1-size-desktop);
}

h2 {
    font-size: var(--header-h2-size-desktop);
    font-weight: 400;
}

h3 {
    font-size: var(--header-h3-size-desktop);
}

h3.small {
    font-size: var(--header-h3-size-mobile);
}

h4 {
    font-size: var(--header-h4-size-desktop);
}

h4.inline {
    display: inline-block;
    font-size: var(--font-size-desktop);
    font-weight: 400;
    margin: 0;
}

h5 {
    font-size: var(--header-h5-size-desktop);
}

h6 {
    font-size: var(--header-h6-size-desktop);
}

p {
    margin: 0 0 var(--spacing-2) 0;
}

a {
    color: var(--color-strong-blue);
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
    -webkit-transition: color 0.15s linear;
    -moz-transition: color 0.15s linear;
    transition: color 0.15s linear;
}

a:hover,
a:focus {
    color: var(--color-strong-blue);
    text-decoration: underline;
    text-decoration-style: solid;
    text-underline-offset: 5px;
}

h3 a {
    text-underline-offset: 6px;
}

code {
    font-family: source-code-pro, Monaco, Consolas, 'Courier New', monospace;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
}

.mt-1 {
    margin-top: var(--spacing-1) !important;
}

.mt-2 {
    margin-top: var(--spacing-2) !important;
}

.mt-3 {
    margin-top: var(--spacing-3) !important;
}

.mt-4 {
    margin-top: var(--spacing-4) !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: var(--spacing-1) !important;
}

.mb-2 {
    margin-bottom: var(--spacing-2) !important;
}

.mb-3 {
    margin-bottom: var(--spacing-3) !important;
}

.mb-4 {
    margin-bottom: var(--spacing-4) !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: var(--spacing-1) !important;
}

.mr-2 {
    margin-right: var(--spacing-2) !important;
}

.mr-3 {
    margin-right: var(--spacing-3) !important;
}

.mr-4 {
    margin-right: var(--spacing-4) !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: var(--spacing-1) !important;
}

.ml-2 {
    margin-left: var(--spacing-2) !important;
}

.ml-3 {
    margin-left: var(--spacing-3) !important;
}

.ml-4 {
    margin-left: var(--spacing-4) !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: var(--spacing-1) !important;
}

.pt-2 {
    padding-top: var(--spacing-2) !important;
}

.pt-3 {
    padding-top: var(--spacing-3) !important;
}

.pt-4 {
    padding-top: var(--spacing-4) !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: var(--spacing-1) !important;
}

.pb-2 {
    padding-bottom: var(--spacing-2) !important;
}

.pb-3 {
    padding-bottom: var(--spacing-3) !important;
}

.pb-4 {
    padding-bottom: var(--spacing-4) !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: var(--spacing-1) !important;
}

.pl-2 {
    padding-left: var(--spacing-2) !important;
}

.pl-3 {
    padding-left: var(--spacing-3) !important;
}

.pl-4 {
    padding-left: var(--spacing-4) !important;
}

.strong {
    font-weight: 500;
}

.nowrap {
    white-space: nowrap;
}

.divider {
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--color-dark-text);
}

.divider.light {
    border-bottom-color: var(--color-light);
}

.divider.spacer {
    border-bottom: none;
}

.text-small {
    font-size: var(--font-size-desktop-small);
}

.text-large {
    font-size: var(--header-h5-size-desktop);
}

/* Global Elements */

.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

section {
    position: relative;
    margin: 0 var(--spacing-6) 0 var(--spacing-6);
    padding: var(--spacing-3) 0 var(--spacing-4) 0;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    transition: all 0.15s linear;
}

.container {
    margin: 0 var(--spacing-horizontal-desktop) 0 var(--spacing-horizontal-desktop);
}

/* Site Content */

.content {
    flex: 1;
    position: relative;
    margin: var(--spacing-nav-height) 0 0 0;
}

.panel {
    position: relative;
    background-color: var(--color-white);
    margin: 0 0 var(--spacing-2) 0;
    padding: var(--spacing-3) var(--spacing-3) var(--spacing-1) var(--spacing-3);
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    transition: all 0.15s linear;
    border-radius: var(--border-radius);
}

.cols {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.cols.centered {
    -webkit-justify-content: center;
    justify-content: center;
}

.cols.center-vertical {
    -webkit-align-items: center;
    align-items: center;
}

.cols.left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}

.cols.right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}
.wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex {
    display: flex;
}

.full {
    margin: 0;
    border-radius: 0;
}

.bg {
    position: relative;
    background-color: var(--color-light-blue);
    color: var(--color-dark-text);
    padding: var(--spacing-4) var(--spacing-6) var(--spacing-4) var(--spacing-6);
    z-index: 0;
    overflow: hidden;
}

.bg:not(.full) {
    border-radius: var(--border-radius);
}

.bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.1) 90%);
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.bg-none {
    background-color: transparent;
}

.bg-none:before {
    background: none;
}

.bg-primary {
    background-color: var(--color-strong-blue);
    color: var(--color-light);
}

.bg-secondary {
    background-color: var(--color-gray);
    color: var(--color-light);
}

.bg-light {
    background-color: var(--color-light);
    color: var(--color-dark-text);
}

.bg-white {
    background-color: var(--color-white);
}

.bg-gradient-dark:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.1) 90%);
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.bg.no-padding {
    padding: 0;
}

.bg-primary h2,
.bg-primary h3,
.bg-primary h4,
.bg-primary h5,
.bg-primary p,
.bg-primary a,
.bg-primary li,
.bg-secondary h2,
.bg-secondary h3,
.bg-secondary h4,
.bg-secondary h5,
.bg-secondary p,
.bg-secondary a,
.bg-secondary li {
    color: var(--color-light);
    text-underline-color: var(--color-light-gray);
}

.bg-white h2,
.bg-white h3,
.bg-white h4,
.bg-white h5,
.bg-white p,
.bg-white a,
.bg-white li {
    color: var(--color-dark-text);
    text-underline-color: var(--color-dark-text);
}

.bg-section {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent none center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

section.overlay-top {
    margin-top: calc(var(--spacing-4) * -1);
}

section.overlay-bottom {
    margin-bottom: calc(var(--spacing-4) * -1);
}

table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 2px;
    overflow: hidden;
}

th, td {
    padding: var(--spacing-1) var(--spacing-2);
    border: 1px solid var(--color-light-gray);
}

table.no-border th,
table.no-border td {
    border: none;
}

table.small th,
table.small td {
    font-size: var(--font-size-desktop-small);
    line-height: 1.2;
    padding: var(--spacing-1) var(--spacing-1);
}

.quote {
    font-size: var(--header-h5-size-desktop);
    font-weight: 500;
    line-height: var(--line-height-header);
    padding: var(--spacing-1) var(--spacing-8) var(--spacing-1) var(--spacing-8);
}

.center,
.quote.center {
    text-align: center;
}

.right,
.quote.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}

img.center {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

img.full {
    width: 100%;
    height: auto;
}

.scale-half {
    transform: scale(0.5);
}

.scale-three-quarters {
    display: flex;
    transform: scale(0.75);
}

.logo {
    width: 100%;
    height: auto;
}

.logo.client {
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 220px;
}

.logo.client.square {
    max-height: 280px;
}

.logo.client.medium {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 180px;
}

.logo.client.small {
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 120px;
}

.logo.client.x-small {
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 90px;
}

.logo.client.x-small.square {
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 120px;
}

.icon {
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    justify-content: center;
    min-width: 96px;
    min-height: 88px;
    margin-right: var(--spacing-3);
}

.icon img {
    display: block;
    width: auto;
    max-width: 96px;
    height: auto;
    max-height: 88px;
}

.icon.medium {
    min-width: 75px;
    min-height: 75px;
}

.icon.medium img {
    max-width: 75px;
    max-height: 75px;
}

.icon.small {
    min-width: 55px;
    min-height: 55px;
}

.icon.small img {
    max-width: 55px;
    max-height: 55px;
}

.icon.right {
    margin-right: 0;
    margin-left: var(--spacing-3);
}

.icon.primary {
    filter: sepia(100%) saturate(1200%) brightness(0.5) hue-rotate(160deg);
}

.icon.secondary {
    filter: brightness(0.34);
}

.icon.white {
    filter: sepia(100%) saturate(0) brightness(0) invert(1);
}

.client-logos .client-logo {
    height: 110px;
    margin: 0 var(--spacing-3);
    pointer-events: none;
}

.client-logos .client-logo.small {
    height: 90px;
}

.client-logos .client-logo.large {
    height: 120px;
}

.service-links,
.service-links ul,
.service-links ol,
.legal ul,
.legal ol,
.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.legal ul,
.legal ol {
    margin: 0 0 var(--spacing-2) 0;
}

.service-links li,
span.service-links,
.legal ul li,
.legal ol li {
    line-height: 1.6;
    margin: 0 0 var(--spacing-half) 0;
}

.service-links li:before,
span.service-links:before,
.legal ul li:before,
.legal ol li:before {
    content: '';
    display: inline-block;
    background: transparent url('../images/icon-dot-circle-dark.svg') left top no-repeat;
    background-size: 100% auto;
    width: 13px;
    height: 13px;
    margin: 0 6px -1px 0;
}

.service-links ul li:before,
.service-links ol li:before,
.legal ul li:before,
.legal ol li:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 6px 0 20px;
}

.service-links.light li:before {
    background-image: url('../images/icon-dot-circle-light.svg');
}

.service-links h3 {
    display: inline-block;
    font-size: 20px;
    margin: 0 10px 2px 0;
}

.btn,
a.btn {
    display: inline-block;
    background-color: var(--color-strong-blue);
    width: fit-content;
    font-size: var(--font-size-btn);
    color: var(--color-white);
    text-align: center;
    text-decoration: none;
    line-height: 1;
    padding: var(--spacing-1) var(--spacing-2);
    border: 2px solid var(--color-strong-blue);
    border-radius: var(--border-radius);
    cursor: pointer;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    transition: all 0.15s linear;
}

.btn:hover,
.btn:active,
a.btn:hover,
a.btn:active {
    text-decoration: none;
    border-color: transparent;
}

.btn-light,
a.btn-light {
    background-color: var(--color-white);
    color: var(--color-dark-text);
    border-color: var(--color-white);
}

.btn-secondary,
a.btn-secondary {
    background-color: var(--color-gray);
    color: var(--color-white);
    border-color: var(--color-gray);
}

.btn.center,
a.btn.center {
    margin: 0 auto;
}

.btn.small,
a.btn.small {
    font-size: var(--font-size-btn-small);
    padding: var(--spacing-half) var(--spacing-2);
}

.btn.btn-close,
a.btn.btn-close {
    position: absolute;
    left: 0;
    right: auto;
    background-color: transparent;
    font-size: var(--header-h4-size-desktop);
    font-weight: 500;
    color: var(--color-dark-text);
    padding: var(--spacing-half) var(--spacing-1);
    border: none;
}

.btn.btn-close.right,
a.btn.btn-close.right {
    left: auto;
    right: 0;
}

.form-switch {
    display: flex;
    flex-direction: column;
    position: relative;
}

.form-switch input[type="checkbox"] {
    width: 0;
    height: 0;
    visibility: hidden;
}

.form-switch label {
    display: block;
    position: relative;
    text-indent: -9999px;
    background-color: grey;
    width: 46px;
    height: 24px;
    font-size: 0;
    line-height: 0;
    border-radius: 46px;
    cursor: pointer;
}

.form-switch label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    background: var(--color-white);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: 0.3s;
}

.form-switch input:checked + label {
    background: var(--color-strong-blue);
}

.form-switch input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

label:active:after {
    width: 20px;
}

.col-2 {
    width: 20%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33333%;
}

.col-6 {
    width: 50%;
}

.cols.wrap .panel.col-6 {
    width: calc(50% - 56px);
}

.col-8 {
    width: 66.6666%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 80%;
}

.desktop-hide {
    display: none !important;
}

.mobile-hide {
    display: flex;
}

td.mobile-hide {
    display: table-cell;
}

.collapsible {
    background-color: var(--color-light);
    margin: 0 0 var(--spacing-1) 0;
    padding: var(--spacing-1) var(--spacing-1);
    border: 1px solid var(--color-light-gray);
}

.collapsible.dark {
    background-color: var(--color-dark-text);
    color: var(--color-white);
    border: 1px solid var(--color-light);
}

.collapsible .top {
    cursor: pointer;
}

.collapsible h4 {
    font-size: var(--header-h5-size-desktop);
    font-weight: 500;
    margin: 0;
}

.collapsible h5 {
    font-size: var(--header-h6-size-desktop);
    font-weight: 500;
}

.switch {

}

/* Media Queries */

@media all and (max-width: 960px) {
    .col-2 {
        width: 25%;
    }

    .col-3 {
        width: 33.33333%;
    }

    .col-4 {
        width: 50%;
    }

    .col-9 {
        width: 66.66666%;
    }
}

@media all and (max-width: 767px) {
    .container {
        margin: 0 var(--spacing-horizontal-mobile) 0 var(--spacing-horizontal-mobile);
    }

    section {
        margin: 0 var(--spacing-4) 0 var(--spacing-4);
        padding: var(--spacing-3) 0 var(--spacing-4) 0;
    }

    section.full {
        margin: 0;
        padding: var(--spacing-3) var(--spacing-4) var(--spacing-4) var(--spacing-4);
    }

    h1 {
        font-size: var(--header-h1-size-mobile);
    }

    h2 {
        font-size: var(--header-h2-size-mobile);
    }

    h3 {
        font-size: var(--header-h3-size-mobile);
    }

    h4 {
        font-size: var(--header-h4-size-mobile);
    }

    h5 {
        font-size: var(--header-h5-size-mobile);
    }

    .quote {
        padding: var(--spacing-1) var(--spacing-5) var(--spacing-1) var(--spacing-6);
    }

    .cols:not(.wrap):not(.mobile) {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .col-2 {
        width: 40%;
    }

    .col-3,
    .col-4,
    .col-6,
    .cols.wrap .panel.col-6,
    .col-8,
    .col-9 {
        width: auto;
    }

    .col-6-mobile {
        width: 50%;
    }

    .btn.sm-center,
    a.btn.sm-center {
        margin: 0 auto;
    }

    .collapsible h4 {
        font-size: var(--header-h6-size-desktop);
    }

    .collapsible h5 {
        font-size: var(--header-h6-size-desktop);
    }

    .table-flow {
        overflow-x: auto;
    }

    .desktop-hide {
        display: flex !important;
    }

    .mobile-hide {
        display: none !important;
    }
}

@media all and (max-width: 600px) {
    section.full {
        padding: var(--spacing-3) var(--spacing-3) var(--spacing-4) var(--spacing-3);
    }

    section.overlay-top {
        margin-top: calc(var(--spacing-2) * -1);
    }

    .bg {
        padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-4);
    }

    .hero-text.has-title {
        top: 40%;
    }

    h2 {
        font-size: var(--header-h4-size-mobile);
    }

    table tr {
        display: block;
        width: 100%;
    }

    table th,
    table td {
        display: block;
    }
}
