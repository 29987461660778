.cookie-consent {
    position: fixed;
    bottom: calc(var(--spacing-10) * -1);
    left: var(--spacing-4);
    display: flex;
    background-color: var(--color-dark-gray);
    width: calc(100% - var(--spacing-8));
    color: var(--color-light);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    -webkit-transition: bottom 0.4s ease;
    -moz-transition: bottom 0.4s ease;
    transition: bottom 0.4s ease;
    z-index: 998;
}

.cookie-consent.active {
    bottom: var(--spacing-4);
}

.cc-content {
    width: 100%;
    padding: var(--spacing-3) var(--spacing-4) var(--spacing-3) var(--spacing-4);
}

.cookie-consent .btn {
    font-size: var(--font-size-desktop);
}

.cookie-settings {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: var(--color-dark-gray);
    width: auto;
    min-width: 0;
    max-width: 0;
    height: auto;
    max-height: 0;
    display: flex;
    color: var(--color-light);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 999;
}

.cookie-settings.active {
    min-width: calc(100% - var(--spacing-8));
    max-width: calc(100% - var(--spacing-8));
    max-height: 80vh;
    pointer-events: all;
}

.settings-content {
    width: 100%;
    min-width: calc(100% - var(--spacing-8));
    padding: var(--spacing-3) var(--spacing-4) var(--spacing-3) var(--spacing-4);
}

.cookie-settings .subheader {
    color: var(--color-strong-blue);
}

.switch {
    margin-top: -20px;
}

@media all and (max-width: 768px) {
    .cookie-consent {
        bottom: calc(var(--spacing-20) * -1);
        left: var(--spacing-2);
        width: calc(100% - var(--spacing-4));
    }

    .cookie-consent.active {
        bottom: var(--spacing-6);
    }

    .cookie-consent .btns {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-top: var(--spacing-2);
    }

    .cookie-consent .btn {
        display: block;
    }

    .cookie-settings {

    }

    .cookie-settings.active {
        min-width: calc(100% - var(--spacing-4));
        max-width: calc(100% - var(--spacing-4));
        max-height: 80vh;
    }

    .cookie-settings .settings-content {
        padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-2);
        overflow-y: auto;
    }

    .cookie-settings .settings-content > .panel {
        padding: var(--spacing-4) var(--spacing-3) var(--spacing-4) var(--spacing-3);
    }

    .switch {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-top: var(--spacing-2);
    }


    .cookie-settings .btns {
        -webkit-align-items: center;
        align-items: center;
    }

    .cookie-settings .btns .btn {
        display: block;
        margin: 0 auto var(--spacing-2) auto;
    }
}
