header {
    background-color: var(--color-white);
    position: fixed;
    width: 100vw;
    z-index: 10;
    transition: all 0.3s ease;
}

header.scrolled {
    box-shadow: 0 3px 10px -3px var(--color-light-gray);
}

header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: var(--spacing-nav-height);
}

header .site-logo {
    display: flex;
    width: 162px;
}

header .site-logo img,
header .site-logo svg {
    width: 100%;
    height: auto;
}

/* Navigation */

.nav-main {
    z-index: 100;
}

.menu-icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
    position: absolute;
    top: var(--spacing-3);
    right: var(--spacing-3);
    background-color: transparent;
    width: 32px;
    height: 32px;
    margin: var(--spacing-half) 0 0 0;
    padding: 0;
    border: none;
}

.menu-icon .icon {
    width: auto;
    height: 100%;
}

.nav-main .menu-icon .menu {
    margin-top: var(--spacing-half);
}

.nav-main.open .menu-icon .menu {
    display: none;
}

.nav-main .menu-icon .close {
    display: none;
}

.nav-main.open .menu-icon .close {
    display: flex;
}

.nav-main .nav-mobile {
    display: none;
}

.nav-main ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    margin: 0;
    padding: 0;
}

.nav-main li {
    position: relative;
    font-size: var(--nav-size-desktop);
    font-weight: 500;
    line-height: 1;
    margin: 0;
}

.nav-main ul ul,
.nav-main ul .mega-menu {
    flex-direction: column;
    display: none;
    position: absolute;
    top: 100%;
    left: calc(var(--spacing-1) * -1);
    background-color: var(--bg-color-header);
    white-space: nowrap;
    margin: var(--spacing-2) 0 0 0;
    padding: var(--spacing-1) var(--spacing-1) 6px var(--spacing-1);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-light-gray);
}

.nav-main ul .mega-menu {
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: var(--spacing-nav-height);
    left: auto;
    right: var(--spacing-4);
    width: 800px;
    max-width: calc(100vw - var(--spacing-6));
    white-space: normal;
    margin: calc(var(--spacing-2) * -1);
    padding: 0;
    overflow: hidden;
}

.nav-main ul .mega-menu li {
    flex: 1;
    padding: 0;
    overflow: hidden;
}

.nav-main ul .mega-menu li.has-border {
    border-right: 1px solid var(--color-light-gray);
}

.nav-main ul ul li {
    display: block;
    font-size: var(--subnav-size-desktop);
    padding: var(--spacing-1) 0 var(--spacing-1) 0;
}

.nav-main a {
    color: var(--color-dark-text);
    text-decoration: none;
    line-height: 1.2;
    padding: var(--spacing-1) var(--spacing-2) var(--spacing-2) var(--spacing-2);
}

.nav-main a.has-submenu {
    padding-bottom: var(--spacing-4);
}

.nav-main a:hover,
.nav-main a:focus {
    color: var(--color-strong-blue);
    text-decoration: none;
}

.submenu-section {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-2) var(--spacing-1) var(--spacing-1) var(--spacing-2);
}

.submenu-section a {
    font-weight: 400;
    padding: var(--spacing-three-quarters) 0 var(--spacing-three-quarters) 0;
}

.submenu-header,
.submenu-header a {
    display: block;
    font-size: var(--nav-size-desktop);
    font-weight: 500;
}

/* Hero */

.hero {
    position: relative;
}

.hero.no-bg {
    background-color: var(--color-dark-gray);
}

.hero.ready {

}

.hero-image {
    position: relative;
    background: transparent none center top no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
}

.hero.no-bg .hero-image {
    position: relative;
    background: none;
    width: 100%;
    height: 0;
    padding-top: 0;
    overflow: hidden;
}

.hero.no-bg.has-overlay {
    padding-bottom: var(--spacing-4);
}

.hero-image img {
    width: 100%;
    height: auto !important;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.hero-image video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.hero-text {
    position: absolute;
    top: 50%;
    left: var(--spacing-6);
    color: var(--color-light);
    margin: 0 var(--spacing-5) 0 var(--spacing-6);
    transform: translateY(-50%);
}

.hero.no-bg .hero-text {
    position: relative;
    top: auto;
    left: auto;
    color: var(--color-light);
    margin: var(--spacing-4) var(--spacing-6) 0 var(--spacing-6);
    padding-bottom: var(--spacing-3);
    transform: none;
}

.hero-text h2 {
    margin: 0;
}

@media all and (min-width: 768px) {
    .nav-main ul li:hover > ul,
    .nav-main ul li:hover > .mega-menu,
    .nav-main ul li:hover > .mega-menu ul {
        display: flex;
    }
}

@media all and (max-width: 767px) {
    .menu-icon {
        display: flex;
    }

    .nav-main .nav-mobile {
        flex-direction: column;
        position: absolute;
        top: var(--spacing-nav-height);
        right: 0;
        background-color: var(--color-white);
        width: calc(100% - var(--spacing-8));
        margin: 0;
        padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) var(--spacing-4);
        border-bottom: 1px solid var(--color-gray);
    }

    .nav-main.open .nav-mobile {
        display: flex;
    }

    .nav-main .nav-mobile li {
        display: block;
        background-color: transparent;
        font-size: var(--nav-size-desktop);
        line-height: 1.8;
        margin: 0;
        padding: var(--spacing-quarter) 0;
        border: none;
        border-radius: 0;
    }

    .nav-main .nav-mobile ul {
        display: flex;
        flex-direction: column;
        position: relative;
        top: auto;
        left: auto;
        background-color: transparent;
        margin: 0;
        padding: 0 0 0 var(--spacing-2);
        border: none;
        border-radius: 0;
    }

    .nav-main .nav-mobile a {
        padding: 0;
    }

    .hero-image {
        min-height: 7vh;
    }

    .hero-image.has-title {
        min-height: 20vh;
    }

    .hero-image video {
        top: 0 !important;
    }

    .hero-text {
        left: 0;
        margin: 0 var(--spacing-4) 0 var(--spacing-4);
    }
}

@media all and (max-width: 600px) {

}
