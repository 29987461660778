footer {
    display: flex;
    flex-direction: column;
}

.sub-footer,
.bottom-bar,
.footer-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px var(--spacing-horizontal-desktop) 12px var(--spacing-horizontal-desktop);
}

.sub-footer {
    background-color: var(--color-gray);
}

.footer-nav {
    list-style-type: none;
}

.footer-nav li {
    margin: 0 0 0 var(--spacing-2);
}

.footer-nav a {
    color: var(--color-white);
    text-decoration: none;
    text-underline-color: var(--color-light-gray);
}

.footer-nav a:hover,
.footer-nav a:focus {
    color: var(--color-light-gray);
}

.bottom-bar {
    background-color: var(--color-dark-gray);
    font-size: 14px;
    font-weight: 400;
    color: var(--color-white);
}

footer .logo {
    width: auto;
    height: 48px;
}

@media all and (max-width: 767px) {
    .sub-footer,
    .bottom-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px var(--spacing-horizontal-mobile) 10px var(--spacing-horizontal-mobile);
    }
}
